/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHtCe4ZRNkAT7MQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHt6e4ZRNkAQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHtCe4ZRNkAT7MQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHt6e4ZRNkAQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHtCe4ZRNkAT7MQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBVXvYC6trAT7RQHt6e4ZRNkAQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RbLtyU5rZPoAU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RVLtyU5rZP.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RbLtyU5rZPoAU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RVLtyU5rZP.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RbLtyU5rZPoAU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBbXvYC6trAT7RVLtyU5rZP.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html,
body {
  height: 100%;
  font-family: "Karla", sans-serif;
  margin: 0;
  font-size: 18px;
}

div {
  display: block;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 1rem);
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem 0;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1rem 0;
}

h2 {
  font-size: 1.8rem;
  letter-spacing: -1px;
  margin-bottom: 3rem;
  padding: 0 1rem;
  &.heading {
    font-size: 6rem;
    letter-spacing: -1px;
    padding: 0 0.6rem;
    font-weight: 700;
    margin: 2rem 0 1rem;
  }
}

h3 {
  font-size: 1.5rem;
}

p {
  margin: 1rem 01 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  a {
    color: #093c9b;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease-in-out;
    animation: rainbow 2s ease-in-out infinite;
    background: linear-gradient(to right, #ee0033, #ffe600, #093c9b);
    background-clip: text;
    background-size: 200% 200%;
    display: inline-block;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 3px;
      bottom: -5px;
      left: 0;
      background-color: #ffe600;
      display: inline-block;
    }
    &:hover {
      color: rgba(0, 0, 0, 0);
    }
  }
}

@keyframes rainbow {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.header {
  display: flex;
  flex-direction: row;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  flex-wrap: wrap;
  .wrapper {
    padding: 2rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  h1 {
    margin: 0;
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    a {
      margin-right: 1rem;
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      text-decoration: none;
      transition: color 0.2s;
      display: flex;
      text-align: center;
      &:hover {
        color: #000;
      }
    }
  }
  .logo {
    display: flex;
    text-transform: lowercase;
    font-weight: 700;
    text-align: center;
    font-size: 4rem;
    letter-spacing: -1px;
    span {
      transition: color 0.3s ease-in-out;
      animation: rainbow 2s ease-in-out infinite;
      background: linear-gradient(to right, #ee0033, #ffe600, #093c9b);
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% 200%;
      cursor: default;
      color: rgba(0, 0, 0, 0);
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      &:hover {
        color: rgba(0, 0, 0, 0);
      }
    }
  }
}

.footer {
  display: flex;
  // position: sticky;
  // bottom: 0;
  max-width: 800px;
  width: 100%;
  margin: auto auto 0;
  background-color: #fff;
  flex-direction: column;
  padding: 1.5rem 0;
  align-items: center;
  p {
    margin: 0 !important;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.homePage,
.aboutPage,
.workPage,
.contactPage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  .content {
    display: block;
    overflow-y: auto;
    padding: 0rem 2rem 0rem 1rem;
    flex: 1;
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      border-right: 1px solid #ffe600;
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffe600;
    }
    .post > p:first-child {
      font-size: 1.3rem;
      line-height: 1.25;
      font-weight: 400;
      color: #093c9b;
    }
  }
}

.aboutPage {
  .post {
    margin-bottom: 2rem;
  }
}

.home {
  // animation-duration: 15s;
  // animation-iteration-count: infinite;
  // animation-direction: alternate;
  // animation-name: bg;
  // background-size: 200% 200%;
  // background-color: #fff;
  .header,
  .footer {
    background: none !important;
  }
  .holder {
    position: relative;
  }
  .wave {
    bottom: -4rem;
    position: relative;
    z-index: -1;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }
  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @keyframes bg {
    0% {
      background: #f1ffe4;
    }
    50% {
      background: #dafdff;
    }
    100% {
      background: #f4eaff;
    }
  }
}

.email {
  color: #ee0033;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease-in-out;
  animation: rainbow 2s ease-in-out infinite;
  background: linear-gradient(to right, #ee0033, #ffe600, #093c9b);
  background-clip: text;
  background-size: 200% 200%;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: #ffe600;
  }
}

.contacts {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.red {
  color: #ee0033;
  font-weight: bold;
}

.phone {
  border: 1px solid #ee0033;
  padding: 1rem;
}

@media (max-width: 768px) {
  .heading {
    font-size: 3rem !important;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 !important;
  }
  .heading {
    font-size: 3rem !important;
    margin-top: 0 !important;
  }
  .header .menu {
    justify-content: flex-start;
  }
}
